<template>
  <div id="pad-view" class="pad-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'AssignmentsAndDiscount'">
    </breadcrumb-base>
    <!--Selected Customers-->

    <vue-custom-scrollbar class="scroll-area"  :settings="scrollSettings" >
      <div class="flex flex-col flex-nowrap lg:flex-row w-full space-y-3 lg:space-y-0 mb-2 lg:mb-4">

        <!--Single Card-->
        <template v-for="(c, index) in selectedCustomers">
          <div class="cursor-pointer px-2 py-2" style="min-width: 250px;">
            <div
              class="widget w-full p-4 rounded-lg bg-white border-4 border-green-400">
              <div class="flex items-center">
                <div class="flex flex-col justify-center">
                  <div class="text-lg font-bold">{{ c.customer_id }}</div>
                  <div class="text-normal font-semibold text-gray-700 truncate">{{ c.username }}</div>
                  <div class="text-sm text-gray-600 truncate">{{ c.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </vue-custom-scrollbar>

    <div class="flex">

      <div class="my-auto mb-0">
        <vs-button @click="showCustomersModal = true">
          {{ $t('SelectCustomers') }}
        </vs-button>
      </div>

      <vs-select v-model="selectedProductType" class="select-large ml-2"
                 :label="$t('ProductType')" @input="onProductTypeSelected">
        <vs-select-item :value="null" :text="$t('All')" />
        <vs-select-item :key="index.key" :value="item.key" :text="item.name"
                        v-for="(item,index) in productTypes" />
      </vs-select>
      <vs-select
        :no-data="$t('NoDiscountSlabs')"
        v-model="selectedDiscountSlab"
        class="select-large ml-2"
        :label="$t('DiscountSlab')"
        @change="onDiscountSlabChanged">
        <vs-select-item :key="index" :value="item" :text="(item.plan_name) + ' - ' + (item.plan_discount) + ' %'"
                        v-for="(item,index) in discountSlabs" />
      </vs-select>
      <vs-spacer></vs-spacer>
      <div class="my-auto mb-0">
        <vs-button @click="updatePADs">
          {{ $t('Update') }}
        </vs-button>
      </div>
    </div>

    <!--Product List-->
    <div class="flex flex-col flex-wrap lg:flex-row w-full space-y-3 lg:space-y-0 mb-2 lg:mb-4">

      <!--Single Card-->
      <template v-for="(p, index) in products">
        <div class="w-full lg:w-1/3 cursor-pointer px-2 py-2">
          <div
            class="widget w-full p-4 rounded-lg bg-white ">
            <div class="flex items-center flex-wrap">
              <div class="flex flex-col justify-center">
                <div class="text-lg font-bold truncate" style="width: 150px;">{{ p.product_name }}</div>
                <div class="text-normal font-semibold text-gray-700 truncate">{{ p.value | germanNumberFormat }}</div>
              </div>
              <vs-spacer></vs-spacer>
              <vs-button
                v-if="selectedProductAddDelete[p.id]"
                @click="clearSelectedProductAddDelete(p.id)"
                color="danger" size="small" icon="close"></vs-button>
              <div class="flex ml-2 flex-col justify-center space-y-2 ">
                <vs-radio v-model="selectedProductAddDelete[p.id]"
                          :vs-value="'add_' + p.id"
                          color="success">
                  <span class="text-sm">
                    {{ $t('Add') }}
                  </span>
                </vs-radio>
                <vs-radio v-model="selectedProductAddDelete[p.id]"
                          :vs-value="'remove_' + p.id"
                          color="danger">
                  <span class="text-sm">
                    {{ $t('Remove') }}
                  </span></vs-radio>
              </div>

              <div class="flex ml-2 flex-col justify-center space-y-2 ">
                <input
                  class="border rounded w-12 h-12 text-center"
                  type="text" v-model="selectedProductDiscount[p.id]">
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>

    <!--Select Customers Modal-->
    <vx-modal v-if="showCustomersModal" component-class="modal-container-7 bg-gray-100">
      <template slot="header"></template>
      <template slot="body">
        <!-- Card layout -->
        <div class="flex items-center justify-center bg-gray-100">
          <div class="w-full mx-auto py-1 sm:px-4 lg:px-4">
            <h1 class="text-center text-xl font-extrabold pb-4">
              {{ $t('Selected') }} {{ selectedCustomerIds.length }} {{ $t('Customers') }}
            </h1>
            <div class="flex flex-wrap space-x-2 px-2">
              <div class="my-auto mb-0">
              <vs-input
                :label="$t('SearchCustomer')"
                v-model="searchCustomerText"></vs-input>
              </div>
              <div class="vx-col mt-3" v-if="showMangerDropDown">

                <label style="font-size: 12px;">{{ $t('Manager') }}
                </label>
                <vs-select
                  autocomplete
                  class=""
                  v-model="selectedManager"
                  :placeholder="$t('Manager')"
                >
                  <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                  <vs-select-item :key="index" :value="customer.user_name" :text="customer.user_name"
                                  v-for="(customer,index) in usersRaw.manager_accounts"/>
                </vs-select>
              </div>

              <div class="vx-col my-auto mb-0" v-if="showRefererDropDown">

                <label style="font-size: 12px; ">{{ $t('Staff') }}
                </label>
                <vs-select
                  autocomplete
                  class=""
                  v-model="selectedReferer"
                  :placeholder="$t('Staff')"
                >
                  <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                  <vs-select-item :key="index" :value="customer.user_name" :text="customer.user_name"
                                  v-for="(customer,index) in staffAccounts"/>
                </vs-select>
              </div>

              <div class="my-auto mb-0">
                <vs-button @click="selectAllCustomers">
                  {{ $t('SelectAll') }}
                </vs-button>
              </div>

            </div>
            <vue-custom-scrollbar class="scroll-area pt-2"  :settings="scrollSettings" >
            <div
              class="flex flex-col flex-wrap lg:flex-row w-full space-y-1 lg:space-y-1 mb-2 lg:mb-2 select-customers-list">

              <!--Single Card-->
              <template v-for="(c, index) in customersList">
                <div class="w-full lg:w-1/4 cursor-pointer px-2 py-1" @click="addRemoveCustomerToSelection(c)">
                  <div
                    :class="isSelectedCustomer(c) ? 'border-4 border-green-400' : 'border-4 border-white'"
                    class="widget w-full p-4 rounded-lg bg-white">
                    <div class="flex items-center">

                      <div class="flex flex-col justify-center">
                        <div class="text-lg font-bold">{{ c.customer_id }}</div>
                        <div class="text-normal font-semibold text-gray-700 truncate">{{ c.username }}</div>
                        <div class="text-sm text-gray-600 truncate">{{ c.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>


            </div>
            </vue-custom-scrollbar>
            <div class="flex items-center justify-center pt-4">
              <vs-button @click="showCustomersModal = false">
                {{ $t('Done') }}
              </vs-button>
            </div>
          </div>
        </div>
      </template>

    </vx-modal>

  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: "PAD",
  components: {VxModal, vueCustomScrollbar},
  data() {
    return {
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Customers', i18n: 'Customers', url: '/customers/view'},
        {title: 'Multiple Discounts', i18n: 'MultipleDiscounts', active: true},
      ],
      customers: [],
      selectedProductType: null,
      productTypes: [
        {
          'name': 'Cash Cards',
          'key': 'cash card',
        },
        {
          'name': 'Gift Cards',
          'key': 'gift card',
        },
        {
          'name': 'Calling Cards',
          'key': 'calling card',
        },
      ],
      showCustomersModal: false,
      selectedCustomerIds: [],
      selectedCustomers: [],
      selectedProductAddDelete: {},
      selectedProductDiscount: {},
      selectedDiscountSlab: null,
      discountSlabError: '',
      searchCustomerText: '',

      selectedManager: null,
      selectedReferer: null,
    }
  },
  computed: {
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    products() {
      return this.$store.state.pad.products || []
      //   .filter((p) => {
      //   if (this.selectedProduct == null) return true
      //   return p.product_name === this.selectedProduct
      // }).filter((p) => {
      //   if (this.selectedDenomination == null) return true
      //   return p.value == this.selectedDenomination
      // })
    },
    discountSlabs() {
      return this.$store.state.pad.discountSlabs
    },
    customersList() {
      return this.customers.filter((c) => {
        return c.customer_id.toString().indexOf(this.searchCustomerText) !== -1 ||
          c.name.indexOf(this.searchCustomerText) !== -1 ||
          c.username.indexOf(this.searchCustomerText) !== -1
      }).filter((c) => {
        if(this.selectedManager == null) return true;
        return c.manager != null && c.manager.indexOf(this.selectedManager) !== -1
      }).filter((c) => {
        if(this.selectedReferer == null) return true;
        return c.refer != null && c.refer.indexOf(this.selectedReferer) !== -1
      })
      // return this.customers
    }
  },
  methods: {
    clearAll() {
      this.selectedCustomerIds = []
      this.selectedCustomers = []
      this.selectedProductAddDelete = {}
      this.selectedProductDiscount = {}
      this.selectedDiscountSlab = null
    },
    clearSelectedProductAddDelete(pad) {
      let obj = this.selectedProductAddDelete
      delete obj[pad]
      this.selectedProductAddDelete = {}
      this.selectedProductAddDelete = obj
    },
    isSelectedCustomer(c) {
      if (c) {
        return this.selectedCustomerIds.indexOf(c.customer_id) !== -1;
      } else {
        return false
      }
    },
    selectAllCustomers() {
      this.customersList.forEach(c => {
        let index = this.selectedCustomerIds.indexOf(c.customer_id);
        if (index === -1) {
          this.selectedCustomerIds.push(c.customer_id)
          this.selectedCustomers.push(c)
        }
      })
    },
    addRemoveCustomerToSelection(c) {
      let index = this.selectedCustomerIds.indexOf(c.customer_id);
      if (index === -1) {
        this.selectedCustomerIds.push(c.customer_id)
        this.selectedCustomers.push(c)
      } else {
        this.selectedCustomerIds.splice(index, 1)
        this.selectedCustomers.splice(index, 1)
      }
    },

    onDiscountSlabChanged(value) {
      if (!value) return
      this.fetchDiscountSlabsProducts()
      this.discountSlabError = false
    },
    fetchDiscountSlabsProducts() {
      const payload = {
        'discount_slab_id': this.selectedDiscountSlab.plan_id,
      }
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabProducts', payload)
        .then((data) => {
          this.$vs.loading.close()
          // productsData
          if (data.message_type !== 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            const assignedProductsData = data.product_configuration_slabs
            const obj = this.selectedProductDiscount
            assignedProductsData.forEach((p) => {
              obj[p.configuration_id] = p.discount
            })
            this.selectedProductDiscount = {}
            this.selectedProductDiscount = obj
          }
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: this.$t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    onProductTypeSelected() {
      this.clearAll()
      this.loadProducts()
    },
    updatePADs() {
      if (this.selectedCustomerIds.length === 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select customer',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const pads = []

      Object.keys(this.selectedProductAddDelete).forEach((id) => {
        if (this.selectedProductAddDelete[id].startsWith("add_")) {
          pads.push({
            'mode': 1,
            'product_configuration_id': id,
            'discount': this.selectedProductDiscount[id]
          })
        } else {
          pads.push({
            'mode': 2,
            'product_configuration_id': id
          })
        }
      });

      const operations = {userIds: this.selectedCustomerIds.join(","), padList: JSON.stringify(pads)}
      // operations.discount_plan_id = 1
      this.$vs.loading()
      return this.$store.dispatch('pad/operationOnPADMultiCustomers', operations)
        .then((data) => {
          this.$vs.loading.close()
          this.loadProducts()
          this.clearAll()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadProducts() {
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchAllPADProducts', {productType: this.selectedProductType})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadCustomer() {
      this.$vs.loading()
      const filter = {
        search_text: '',
        staff_details: 1,
      }
      if(this.onlyActive) {
        filter['is_active'] = 1;
      }
      this.$store.dispatch('reports/searchCustomers', { filter })
      // this.$store.dispatch('customer/fetchCustomers', {filters: {}, page: '0', search: '', limit: '200'})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.customers = data.customers
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchDiscountSlabs() {
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabs')
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: $t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchDiscountSlabs();
    this.loadCustomer();
    this.loadProducts();
    this.loadReferer();
  }
}
</script>

<style scoped lang="scss">
#pad-view {
  .modal-body {
    overflow: hidden;
  }
  .modal-container {
    overflow: hidden;
  }
  .select-customers-list {
    max-height: 460px !important;
  }
}
</style>
